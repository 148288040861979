
// import React from 'react';
// import modalXIcon_dm from '../../assets/modalx_logo_for_landingpage_dm.png';
// import { Box, Typography, Button, useMediaQuery } from '@mui/material';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import XIcon from '@mui/icons-material/X';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from '../../constants/colors';
// import { useNavigate } from 'react-router-dom';

// const Footer = () => {
//   const isBelow991 = useMediaQuery("(max-width:991px)");
//   const isBelow660 = useMediaQuery("(max-width:660px)") ;
//   const navigate = useNavigate();

//   const handleRedirect = (link) => {
//     window.open(link, '_blank'); // Opens the link in a new tab
//   };

//   const handleNavigation = (path) => {
//     navigate(path); // Navigates to the specified path
//     window.scrollTo(0, 0);
//   };
  
//   return (
//     <Box sx={{ height: 'auto', margin: '45px 108px'}}>
//       <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px',flexDirection:isBelow991?'column':'row' }}>
//         <Box sx={{ maxWidth: isBelow991?'100%':'380px',paddingBottom:'30px'}}>
//           <img src={modalXIcon_dm} alt="ModalX" style={{ marginBottom: '20px' }} />
//           <Typography variant="body2" sx={{ paddingLeft: '10px', color: 'rgba(255,255,255,0.8)' ,fontSize:'16px'}}>
//             ModalX is creating a unified experience by curating the best AI tools, customizing them for specific tasks,
//             and simplifying human interaction with multimodal generative AI to achieve highly personalized outcomes for
//             companies and users
//           </Typography>
//         </Box>
//         <Box sx={{display:'flex',justifyContent:'space-between',width:isBelow991?'100%':'720px',marginLeft:'10px'}}>
//           <Box>
//             <Typography variant="h6" sx={{ marginBottom: '36px' }}>Pages</Typography>
//             <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)' }}>
//               <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/')}>Home</Typography>
//               <Typography sx={{ mb: '10px' }}>Warroom</Typography>
//               <Typography sx={{ mb: '10px' }}>API</Typography>
//               <Typography sx={{ mb: '10px' }}>Pricing</Typography>
//               <Typography sx={{ mb: '10px' }}>Blog</Typography>
//               <Typography sx={{ mb: '10px' }}>Company</Typography>
//               <Typography sx={{ mb: '10px' }}>Pricing</Typography>
//             </Box>
//           </Box>
//           <Box>
//             <Typography variant="h6" sx={{ marginBottom: '36px' }}>Solutions</Typography>
//             <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)' }}>
//               <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/')}>Home</Typography>
//               <Typography sx={{ mb: '10px' }}>Image</Typography>
//               <Typography sx={{ mb: '10px' }}>Audio</Typography>
//               <Typography sx={{ mb: '10px' }}>Video</Typography>
//               <Typography sx={{ mb: '10px' }}>Presentations</Typography>
//               <Typography sx={{ mb: '10px' }}>Blog</Typography>
//               <Typography sx={{ mb: '10px' }}>Pricing</Typography>
//             </Box>
//           </Box>
//           <Box>
//             <Typography variant="h6" sx={{ marginBottom: '36px' }}>Company</Typography>
//             <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)' }}>
//               <Typography sx={{ mb: '10px' }}>About</Typography>
//               <Typography sx={{ mb: '10px' }}>Careers</Typography>
//               <Typography sx={{ mb: '10px' }}>Contact</Typography>
//               <Typography sx={{ mb: '10px' }}>Newsroom</Typography>
//               <Typography sx={{ mb: '10px' }}>Security</Typography>
//               <Typography sx={{ mb: '10px' }}>Legal</Typography>
//               <Typography sx={{ mb: '10px' }}>Contact Sales</Typography>
//             </Box>
//           </Box>
//           <Box>
//             <Typography variant="h6" sx={{ marginBottom: '36px' }}>Support</Typography>
//             <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)'}}>
//               <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/faq')}>FAQ</Typography>
//               <Typography sx={{ mb: '10px' }}>Privacy Policy</Typography>
//               <Typography sx={{ mb: '10px' }}>Help Center</Typography>
//               <Typography sx={{ mb: '10px' }}>Licenses</Typography>
//               <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/termsofservice')}>Terms of Use</Typography>
//             </Box>
//           </Box>
//         </Box>
        
//       </Box>
//       <Box sx={{ height: '64px', display: 'flex', justifyContent: 'space-between', alignItems: 'end', padding: '0 20px',borderTop:'1px solid rgba(255,255,255,0.2)'}}>
//         <Typography sx={{ margin: 0 }}>© 2024 ModalX | All rights reserved</Typography>
//         <Box sx={{ display: 'flex', gap: '10px' }}>
//           <Button sx={{ height: '34px', width: '34px', minWidth: '34px', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm, '&:hover': { bgcolor: hovered_chat_bg_color_dm } }} onClick={() => handleRedirect('https://www.facebook.com/share/sFKaPdRs2hpBsJHi/?mibextid=LQQJ4d')}>
//             <FacebookIcon />
//           </Button>
//           <Button sx={{ height: '34px', width: '34px', minWidth: '34px', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm, '&:hover': { bgcolor: hovered_chat_bg_color_dm } }} onClick={() => handleRedirect('')}>
//             <MailOutlineIcon />
//           </Button>
//           <Button sx={{ height: '34px', width: '34px', minWidth: '34px', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm, '&:hover': { bgcolor: hovered_chat_bg_color_dm } }} onClick={() => handleRedirect('https://x.com/modalxai?s=21')}>
//             <XIcon />
//           </Button>
//           <Button sx={{ height: '34px', width: '34px', minWidth: '34px', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm, '&:hover': { bgcolor: hovered_chat_bg_color_dm } }} onClick={() => handleRedirect('https://www.linkedin.com/company/modalx/')}>
//             <LinkedInIcon />
//           </Button>
//           <Button sx={{ height: '34px', width: '34px', minWidth: '34px', color: '#FFFFFF', bgcolor: selected_chat_bg_color_dm, '&:hover': { bgcolor: hovered_chat_bg_color_dm } }} onClick={() => handleRedirect('https://www.instagram.com/modalx.ai/?igsh=MXJ3MWszZDVtN2t0MA%3D%3D')}>
//             <InstagramIcon />
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Footer;


import React from 'react';
import modalXIcon_dm from '../../assets/modalx_logo_for_landingpage_dm.png';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { hovered_chat_bg_color_dm, selected_chat_bg_color_dm } from '../../constants/colors';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const isBelow991 = useMediaQuery("(max-width:991px)");
  const isBelow660 = useMediaQuery("(max-width:660px)");
  const navigate = useNavigate();

  const handleRedirect = (link) => {
    window.open(link, '_blank'); // Opens the link in a new tab
  };

  const handleNavigation = (path) => {
    navigate(path); // Navigates to the specified path
    window.scrollTo(0, 0);
  };
  
  return (
    <Box sx={{ height: 'auto', margin: isBelow991?'20px':'45px 108px'}}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px', flexDirection: isBelow991 ? 'column' : 'row' }}>
        <Box sx={{ maxWidth: isBelow991 ? '100%' : '380px', paddingBottom: '30px' }}>
          <img src={modalXIcon_dm} alt="ModalX" style={{ marginBottom: '20px' }} />
          <Typography variant="body2" sx={{ paddingLeft: '10px', color: 'rgba(255,255,255,0.8)' ,fontSize:isBelow660?'14px':'16px'}}>
            ModalX is creating a unified experience by curating the best AI tools, customizing them for specific tasks,
            and simplifying human interaction with multimodal generative AI to achieve highly personalized outcomes for
            companies and users.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: isBelow991 ? '100%' : '720px', marginLeft: '10px', flexWrap: 'wrap' }}>
          <Box sx={{ width: isBelow660 ? '48%' : '22%', marginBottom: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '36px'}}>Pages</Typography>
            <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)' }}>
              <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/')}>Home</Typography>
              <Typography sx={{ mb: '10px' }}>WorkSpace</Typography>
              <Typography sx={{ mb: '10px' }}>API</Typography>
              <Typography sx={{ mb: '10px' }}>Pricing</Typography>
              <Typography sx={{ mb: '10px' }}>Blog</Typography>
              <Typography sx={{ mb: '10px' }}>Company</Typography>
              <Typography sx={{ mb: '10px' }}>Pricing</Typography>
            </Box>
          </Box>
          <Box sx={{ width: isBelow660 ? '48%' : '22%', marginBottom: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '36px' }}>Solutions</Typography>
            <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)' }}>
              <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/')}>Home</Typography>
              <Typography sx={{ mb: '10px' }}>Image</Typography>
              <Typography sx={{ mb: '10px' }}>Audio</Typography>
              <Typography sx={{ mb: '10px' }}>Video</Typography>
              <Typography sx={{ mb: '10px' }}>Presentations</Typography>
              <Typography sx={{ mb: '10px' }}>Blog</Typography>
              <Typography sx={{ mb: '10px' }}>Pricing</Typography>
            </Box>
          </Box>
          <Box sx={{ width: isBelow660 ? '48%' : '22%', marginBottom: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '36px' }}>Company</Typography>
            <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)' }}>
              <Typography sx={{ mb: '10px' }}>About</Typography>
              <Typography sx={{ mb: '10px' }}>Careers</Typography>
              <Typography sx={{ mb: '10px' }}>Contact</Typography>
              <Typography sx={{ mb: '10px' }}>Newsroom</Typography>
              <Typography sx={{ mb: '10px' }}>Security</Typography>
              <Typography sx={{ mb: '10px' }}>Legal</Typography>
              <Typography sx={{ mb: '10px' }}>Contact Sales</Typography>
            </Box>
          </Box>
          <Box sx={{ width: isBelow660 ? '48%' : '22%', marginBottom: '20px' }}>
            <Typography variant="h6" sx={{ marginBottom: '36px' }}>Support</Typography>
            <Box sx={{ fontSize: '16px', color: 'rgba(255,255,255,0.8)'}}>
              <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/faq')}>FAQ</Typography>
              <Typography sx={{ mb: '10px' }}>Privacy Policy</Typography>
              <Typography sx={{ mb: '10px' }}>Help Center</Typography>
              <Typography sx={{ mb: '10px' }}>Licenses</Typography>
              <Typography sx={{ mb: '10px', cursor: 'pointer' }} onClick={() => handleNavigation('/termsofservice')}>Terms of Use</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
  sx={{
    height: '64px',
    display: 'flex',
    justifyContent: isBelow660 ? 'center' : 'space-between',
    alignItems: isBelow660 ? 'center' : 'end',
    padding: '0 20px',
    borderTop: '1px solid rgba(255,255,255,0.2)',
    flexDirection: isBelow660 ? 'column' : 'row',
    gap: isBelow660 ? '20px' : '0',
  }}
>
  <Typography sx={{ margin: 0, textAlign: isBelow660 ? 'center' : 'left', marginTop : isBelow991 ? '80px' : '40px' }}>
    © 2024 ModalX | All rights reserved
  </Typography>
  <Box
    sx={{
      display: 'flex',
      gap: '10px',
      justifyContent: isBelow660 ? 'center' : 'flex-start',
      paddingBottom : isBelow991 ? '15px' : '',

    }}
  >
    <Button
      sx={{
        height: '34px',
        width: '34px',
        minWidth: '34px',
        color: '#FFFFFF',
        bgcolor: selected_chat_bg_color_dm,
        '&:hover': { bgcolor: hovered_chat_bg_color_dm },
      }}
      onClick={() =>
        handleRedirect('https://www.facebook.com/share/sFKaPdRs2hpBsJHi/?mibextid=LQQJ4d')
      }
    >
      <FacebookIcon />
    </Button>
    <Button
      sx={{
        height: '34px',
        width: '34px',
        minWidth: '34px',
        color: '#FFFFFF',
        bgcolor: selected_chat_bg_color_dm,
        '&:hover': { bgcolor: hovered_chat_bg_color_dm },
      }}
      onClick={() => handleRedirect('mailto:noreply@ModalX.ai')}
    >
      <MailOutlineIcon />
    </Button>
    <Button
      sx={{
        height: '34px',
        width: '34px',
        minWidth: '34px',
        color: '#FFFFFF',
        bgcolor: selected_chat_bg_color_dm,
        '&:hover': { bgcolor: hovered_chat_bg_color_dm },
      }}
      onClick={() => handleRedirect('https://x.com/modalxai?s=21')}
    >
      <XIcon />
    </Button>
    <Button
      sx={{
        height: '34px',
        width: '34px',
        minWidth: '34px',
        color: '#FFFFFF',
        bgcolor: selected_chat_bg_color_dm,
        '&:hover': { bgcolor: hovered_chat_bg_color_dm },
      }}
      onClick={() =>
        handleRedirect('https://www.linkedin.com/company/modalx/')
      }
    >
      <LinkedInIcon />
    </Button>
    <Button
      sx={{
        height: '34px',
        width: '34px',
        minWidth: '34px',
        color: '#FFFFFF',
        bgcolor: selected_chat_bg_color_dm,
        '&:hover': { bgcolor: hovered_chat_bg_color_dm },
      }}
      onClick={() =>
        handleRedirect(
          'https://www.instagram.com/modalx.ai/?igsh=MXJ3MWszZDVtN2t0MA%3D%3D'
        )
      }
    >
      <InstagramIcon />
    </Button>
  </Box>
</Box>

    </Box>
  );
};

export default Footer;




